import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import Quasar from 'quasar/src/vue-plugin.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import BottomSheet from 'quasar/src/plugins/bottom-sheet/BottomSheet.js';;
import quasarUserOptions from "./quasar-user-options";
import vue3GoogleLogin from "vue3-google-login";
import VueAxios from "vue-axios";
import axios from "axios";
import VueCookies from "vue-cookies";
import store from "./store";
import Vuex from "vuex";

const app = createApp(App);

app.use(VueAxios, axios);

app.use(Vuex);

app.use(store);

app.use(VueCookies);

app.use(vue3GoogleLogin, {
  clientId:
    "831837968850-h074kial8d141302oekbl4pnj95hjg0u.apps.googleusercontent.com",
});

app.use(
  Quasar,
  { plugins: { Dialog, Notify, BottomSheet } },
  quasarUserOptions
);

app.use(router);

app.mount("#app");
