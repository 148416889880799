<template>
  <q-layout view="hhh lpR fFf">
    <q-header class="bg-primary text-white" hight-hint="98">
      <q-toolbar>
        <q-toolbar-title>
          <!-- <q-avatar>
            <img src="https://cdn.quasar.dev/logo-v2/svg/logo-mono-white.svg" />
          </q-avatar> -->
          <q-btn flat dense label="Lehrerplaner" to="/" style="font-size: 28px; font-weight: bold"/>
        </q-toolbar-title>
        <q-btn
          flat
          @click="drawerRight = !drawerRight"
          round
          dense
          icon="menu"
        />
      </q-toolbar>
    </q-header>

    <q-drawer v-model="drawerRight" side="right" bordered width="200px">
      <q-list>
        <q-item
          :class="{ hidden: loggedIn }"
          clickable
          @click="loginPrompt = true; drawerRight = false"
          exact
        >
          <q-avatar icon="login" />
          <q-item-section> Login </q-item-section>
        </q-item>

        <q-item
          :class="{ hidden: !loggedIn }"
          clickable
          @click="logout(); drawerRight = false"
          exact
        >
          <q-avatar icon="logout" />

          <q-item-section>
            <q-item-label> Hallo{{ userName }}! </q-item-label>
            <q-item-label caption> Logout </q-item-label>
          </q-item-section>
        </q-item>

        <q-item clickable to="/home" @click="drawerRight = false" exact>
          <q-avatar icon="home" />
          <q-item-section> Start </q-item-section>
        </q-item>

        <q-item
          :class="{ hidden: !loggedIn || (loggedIn && !schoolyearAvailable) }"
          clickable
          to="/plan"
          @click="drawerRight = false"
          exact
        >
          <q-avatar icon="preview" />
          <q-item-section> Verlaufsplan </q-item-section>
        </q-item>

        <q-item
          :class="{ hidden: !loggedIn || (loggedIn && !schoolyearAvailable) }"
          clickable
          to="/timetable"
          @click="drawerRight = false"
          exact
        >
          <q-avatar icon="calendar_view_week" />
          <q-item-section> Stundenplan </q-item-section>
        </q-item>

        <q-item :class="{ hidden: !loggedIn }"
          clickable to="/calendar" 
          @click="drawerRight = false"
            exact
        >
          <q-avatar icon="calendar_month" />
          <q-item-section> Kalender </q-item-section>
        </q-item>

        <q-item
          :class="{ hidden: !loggedIn }"
          clickable
          to="/editGenericUnits"
          @click="drawerRight = false"
          exact
        >
          <q-avatar icon="edit_note" />
          <q-item-section> Baisispläne </q-item-section>
        </q-item>

        <q-item clickable to="/about" @click="drawerRight = false" exact>
          <q-avatar icon="info_outline" />
          <q-item-section> About </q-item-section>
        </q-item>

        <q-item clickable @click="$q.dark.toggle()">
          <q-avatar icon="dark_mode" />
          <q-item-section>
            <q-item-label> DarkMode </q-item-label>
            <q-item-label caption> Toggle Darkmode </q-item-label>
          </q-item-section>
        </q-item>
      </q-list>
    </q-drawer>

    <q-dialog v-model="loginPrompt">
      <LoginPopup @already-logged-in="alreadyLoggedIn" />
    </q-dialog>

    <q-dialog v-model="loggedInOnOtherDevicePrompt" persistent>
      <q-card>
        <q-card-section class="row items-center">
          <q-avatar icon="sync_alt" color="red" text-color="white" />
          <span class="q-ml-sm"
            >Du bist bereits von einem anderen Gerät aus eingeloggt. Du wurdest
            dort ausgeloggt und kannst nun hier weiterarbeiten.</span
          >
        </q-card-section>
        <q-card-actions align="right">
          <q-btn
            flat
            label="Okey"
            color="primary"
            @click="loggedInOnOtherDevicePrompt = false"
          />
        </q-card-actions>
      </q-card>
    </q-dialog>

    <q-page-container
      style="padding-left: 0px; padding-right: 0px; overflow: auto"
    >
      <q-page class="q-pa-md">
        <router-view></router-view>
      </q-page>
    </q-page-container>

    <footer></footer>
  </q-layout>
</template>

<script>
import LoginPopup from "../src/components/LoginPopup.vue";
import { ref } from "vue";
import { useQuasar } from "quasar";
import { googleLogout } from "vue3-google-login";
import authHeader from "./services/auth-header";

export default {
  components: {
    LoginPopup,
  },

  name: "App",

  setup() {
    const $q = useQuasar();
    $q.dark.set(false);

    return {
      loginPrompt: ref(false),
      loggedInOnOtherDevice: ref(false),
      loggedInOnOtherDevicePrompt: ref(false),
      drawerRight: ref(true),
      schoolyearAvailable: ref(false),
      // miniState: ref(true),

      showNotif(msg, color) {
        $q.notify({
          message: msg,
          color: color,
        });
      },
    };
  },

  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL, //`${this.apiUrl}`
    };
  },

  created() {
    let lsParsed = JSON.parse(localStorage.getItem("user"));
    if (!lsParsed) {
      return;
    } else if (this.$cookies.isKey("user")) {
      this.$store.commit("changeLoggedIn", true);
      this.$store.commit("changeGivenName", lsParsed.givenName);
    }
  },

  mounted() {
    try {
      this.getAllSchoolyears();
    } catch (error) {
      this.showNotif(error.message, "negative");
    }
  },

  methods: {
    async logout() {
      try {
        googleLogout();
        localStorage.removeItem("user");
        const config = authHeader(this.$cookies);
        await this.axios.post(`${this.apiUrl}/logoutUser`, {}, config);
        this.$cookies.remove("user");
        this.$store.commit("changeLoggedIn", false);
        this.$store.commit("changeGivenName", "");
        this.$router.push("/home");
      } catch (error) {
        if (error.response.status !== 401 && error.response.status !== 403) {
          this.showNotif(error.message, "negative");
        }
      }
    },

    alreadyLoggedIn(loggedInOnOtherDevice) {
      if (loggedInOnOtherDevice == true) {
        this.loggedInOnOtherDevicePrompt = true;
      }
      this.getAllSchoolyears();
    },

    async getAllSchoolyears() {
      try {
        const config = authHeader(this.$cookies);
        const res = await this.axios.get(`${this.apiUrl}/schoolyear`, config);
        if (res.data.length > 0) {
          this.schoolyearAvailable = true;
        }
      } catch (error) {
        return;
      }
    },
  },

  computed: {
    loggedIn() {
      return this.$store.state.user.loggedIn;
    },

    userName() {
      if (this.$store.state.user.givenName !== "") {
        return " " + this.$store.state.user.givenName;
      } else {
        return "";
      }
    },
  },
};
</script>

<style></style>
