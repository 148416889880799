import { createStore } from "vuex";

const store = createStore({
  state: {
    user: {
      loggedIn: false,
      givenName: "",
    },
  },
  mutations: {
    changeLoggedIn(state, loggedIn) {
      state.user.loggedIn = loggedIn;
    },
    changeGivenName(state, givenName) {
      state.user.givenName = givenName;
    },
  },
  actions: {},
  modules: {},
  getters: {
    getLoggedIn(state) {
      return state.user.loggedIn;
    },
    getGivenName(state) {
      return state.user.givenName;
    },
  },
});

export default store;
