const routes = [
  {
    path: "/home",
    name: "home",
    component: () => import("../pages/Index.vue"),
    alias: "/",
  },
  {
    path: "/plan",
    name: "plan",
    component: () => import("../pages/Planning.vue"),
  },
  {
    path: "/editPlan",
    name: "editPlan",
    component: () => import("../pages/EditPlan.vue"),
  },
  {
    path: "/newCalendar",
    name: "newCalendar",
    component: () => import("../pages/newCalendar.vue"),
  },
  {
    path: "/editGenericUnits",
    name: "editGenericUnits",
    component: () => import("../pages/editGenericUnits.vue"),
  },
  {
    path: "/calendar",
    name: "calendar",
    component: () => import("../pages/viewCalendar.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../pages/aboutPage.vue"),
  },
  {
    path: "/timetable",
    name: "timetable",
    component: () => import("../pages/timetable.vue"),
  },
  // {
  //   path: "/test",
  //   name: "test",
  //   component: () => import("../pages/test.vue"),
  // },
  // {
  //   path: "/oldBearbeiten",
  //   name: "bearbeiten",
  //   component: () => import("../pages/Bearbeiten.vue"),
  // },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: "/:catchAll(.*)*",
    component: () => import("../pages/Error404.vue"),
  },
];

export default routes;
