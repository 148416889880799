import { createRouter, createWebHistory } from "vue-router";
import routes from "./routes";

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

// router.beforeEach((to, from, next) => {
//   const publicPages = ["/home", "/about"];
//   const authRequired = !publicPages.includes(to.path);
//   const loggedIn = localStorage.getItem("user");

//   //trying to access a restricted page + not logged in --> redirect to /home
//   if (authRequired && !loggedIn) {
//     next("/home");
//   } else {
//     next("/home");
//   }
// });

export default router;
